:root {
  --blue: #007af3;
  --navBlack: #161414;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
}

.header {
  color: var(--blue);
  font-size: 250px;
}

.text {
  color: white;
  font-size: 50px;
}

.btn {
  text-transform: uppercase;
  color: var(--blue);
  text-decoration: none;
  border: 2px solid;
  background: transparent;
  padding: 10px 40px;
}

.btn:hover {
  color: #ff005a;
}

@media only screen and (max-width: 481px) {
  .header {
    font-size: 150px;
  }

  .text {
    font-size: 35px;
  }
}

@media only screen and (max-width: 245px) {
    .header {
      font-size: 100px;
    }
  
    .text {
      font-size: 25px;
    }
  }
