:root {
  --blue: #007af3;
  --navBlack: #161414;
}

.container {
  text-align: left;
  margin: 100px auto auto 50px;
  color: white;
}

.container .name {
  font-size: 70px;
}

.container .desc {
  font-size: 55px;
}

#developerBlue {
  color: var(--blue);
}

@media only screen and (max-width: 821px) {
  .container {
    text-align: center;
  }
}

@media only screen and (max-width: 382px) {
  .container {
    text-align: center;
    margin: auto;
  }
  .container .name {
    font-size: 45px;
  }

  .container .desc {
    font-size: 30px;
  }
}

/*li {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  list-style-type: none;
}*/

.container_icon {
    width: 100%;
    background-color: #555;
    overflow: auto;
  }
  
  .container_icon a {
    float: left;
    padding: 12px;
    color: white;
    text-decoration: none;
    font-size: 17px;
  }
  
  .container_icon a:hover {
    background-color: #000;
  }


