.container {
  text-align: left;
  margin: 10px auto auto 50px;
  color: white;
}

.song {
  font-size: 23px;
  color: white;
}

a {
  color: white;
  text-decoration: underline;
}

a:hover {
  color: #007af3;
}

@media only screen and (max-width: 821px) {
  .container {
    text-align: center;
  }
}

@media only screen and (max-width: 382px) {
  .container {
    text-align: center;
    margin: auto;
  }

  .song {
    font-size: 15px;
  }
}

#spotify_logo {
  color: #1db954;
}

#activity_logo {
  margin-top: none;
  margin-right: 5px;
}
