@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Shadows+Into+Light&display=swap');

.hero {
    margin-top: 100px;
    text-align: center;
    padding: 0;
    margin-bottom: 0;
    color: white;
    font-family: 'Shadows Into Light', cursive;
    font-size: 70px;
}

.hero:hover {
    color: #66ccff;
}


.TOPG {
    width: 300px;
    height: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    overflow: hidden;

}

.TOPG:hover {
    transform: rotate(360deg);
    transition: .5s;
}

.bugatti {
    overflow: hidden;
    width: 200px;
    height: 120px;
    margin-left: 150px;
    margin-right: auto;
    margin-top: 50px;
    display: block
    
}

.bugattiText {
    color: white;
    margin-left: 150px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
}