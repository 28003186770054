:root {
  --blue: #007af3;
  --navBlack: #161414;
}

#discord_icon {
  color: white;
}

#github_icon {
  color: white;
}

#discord_icon:hover {
  color: var(--blue);
}

#github_icon:hover {
  color: var(--blue);
}

#tag {
  color: var(--blue);
  font-size: 23px;
}

#mail_icon {
  font-size: 25px; 
}

#mail_icon:hover {
  color: var(--blue)
}




